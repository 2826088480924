<template>
    <main>
        <page-header-compact>Configuracion Enunciados - Escala - Abreviada - Desarrollo</page-header-compact>
        <div class="container-xl px-4 mt-n15">
        
        <div class="card mt-4">
            <div class="card-header bg-light">
                <div class="row">
                    <div class="col-6 ">
                        RANGOS : {{rangos.nombre}} ,  De: {{rangos.edad_inicio}} {{rangos.tipo_edad_inicio}} a {{rangos.edad_final}} {{rangos.tipo_edad_final}}
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                        <button @click="modificar=false; abrirModal();" type="button" class="btn btn-primary ">
                            Crear Enunciado&nbsp;<i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body ">
            
            <div class="modal fade" id="modalItemAiepi" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{{tituloModal}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form >
                                        <div class="form-group">
                                            <label class="form-label">Dimension</label>
                                            <select  v-model="enunciado_rango.id_dimension"    class="form-control"  :class="{'is-invalid':$v.enunciado_rango.id_dimension.$error && $v.enunciado_rango.id_dimension.$dirty}">
                                                <option :value="item.id" v-for="item in dimensiones" :key="item.id">
                                                    {{ item.nombre }}
                                                </option >
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Orden</label>
                                            <input type="number" class="form-control" v-model="enunciado_rango.orden"  :class="{'is-invalid':$v.enunciado_rango.orden.$error && $v.enunciado_rango.orden.$dirty}">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Enunciado</label>
                                            <input type="text" class="form-control" v-model="enunciado_rango.enunciado"  :class="{'is-invalid':$v.enunciado_rango.enunciado.$error && $v.enunciado_rango.orden.$dirty}">
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-success" @click="guardar()">
                                        <i class="fas fa-save"></i>&nbsp;Guardar
                                     </button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mt-2" v-for="item in dimensiones" :key="item.id">
                        <div class="card-header bg-light ">{{item.nombre}}</div>
                        <div class="card-body">
                            
                            <div class="table table-responsive"  >
                                <table class="table table-striped table-bordered " >
                                    <tr class="table-primary">
                                        <th>Orden</th>
                                        <th>Enunciado</th>
                                        <th></th>
                                    </tr>          
                                    <tr v-for="ite in item.enunciados" :key="ite.id"  >
                                        
                                        <td>{{ite.orden}}</td>
                                        <td>{{ite.enunciado}}</td>
                                        <td>
                                        <div class="form-row">
                                                <div class="form-group px-2">
                                                    <button @click="modificar=true; abrirModal(ite);" class="btn btn-outline-primary lift" >
                                                        <i class="fa fa-edit fa-fw"></i>Editar
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>   
            </div>         
        </div>
        </div>
    </main>
</template>
<script>

import $ from 'jquery';
import Toast from '../../../../../components/common/utilities/toast';
import {required} from "vuelidate/lib/validators";
import PageHeaderCompact from "../../../../../components/layouts/content/page-header-compact";
import rangoEscalaAbreviadaDesarrolloService from '../../../../../services/rangoEscalaAbreviadaDesarrolloService';
import enunciadosEscalaAbreviadaDesarrolloService from '../../../../../services/enunciadosEscalaAbreviadaDesarrolloService';
import dimensionesEscalaAbreviadaDesarrolloService from '../../../../../services/dimensionesEscalaAbreviadaDesarrolloService';

export default {
    components: {PageHeaderCompact},
    data(){
        return{
            id:0,
            modificar:true,
            tituloModal:'',
            enunciado_rango:{
                id:'',
                orden:'',
                id_rango:'',
                id_dimension : '',
                enunciado : '',
            },
            idRango:'',
            rangos:{},
            enunciados:{},
            dimensiones:[],

        }
    },
    validations (){
        return {
            enunciado_rango:{
                orden:{required},
                id_rango:{required},
                id_dimension:{required},
                enunciado:{required},
            },
        }
    },
    methods:{
        async abrirModal(datos={}){
            $('#modalItemAiepi').modal('show');
            if(this.modificar){
                this.enunciado_rango.id=datos.id;
                this.tituloModal="Modificar Enunciados";
                this.enunciado_rango.id_rango=datos.id_rango;
                this.enunciado_rango.id_dimension=datos.id_dimension;
                this.enunciado_rango.orden=datos.orden;
                this.enunciado_rango.enunciado=datos.enunciado;
            }else{
                this.id=0;
                this.tituloModal="Crear Enunciados";
                this.enunciado_rango.id_rango="";
                this.enunciado_rango.id_dimension="";
                this.enunciado_rango.orden="";
                this.enunciado_rango.enunciado="";
            }
        },
        async cargarRango(id){
            const response = await rangoEscalaAbreviadaDesarrolloService.show(id);
            this.rangos=response.data;
        },
        async cargarEnunciados(id){
            const response = await enunciadosEscalaAbreviadaDesarrolloService.showByRan(id);
            this.enunciados = response.data.map(x=>{
                return {
                    ...x,
                    nombre:x.dimension.nombre,
                }
            });
           
        },
        async cargarDimensiones(){
            const response = await dimensionesEscalaAbreviadaDesarrolloService.index({id_rango:this.idRango});
            //this.dimensiones=response.data;
             this.dimensiones = response.data.map(x=>{
                return {
                    ...x,
                    orden:x.enunciados.orden,
                    enunciado:x.enunciados.enunciado,
                    id_rango:x.enunciados.id_rango,
                }
            });
        },
        async guardar(){ 
            try {
                this.enunciado_rango.id_rango=this.idRango;
                this.$v.enunciado_rango.$touch();
                if(this.$v.enunciado_rango.$invalid){
                    return 
                }
                
                if(this.modificar){
                    this.LoaderSpinnerShow();
                    await enunciadosEscalaAbreviadaDesarrolloService.update(this.enunciado_rango);
                    this.LoaderSpinnerHide();
                    Toast.fire({
                        icon: 'success',
                        title: 'Datos guardados con exito'
                    });
                    $('#modalItemAiepi').modal('hide');
                    this.cargarRango(this.enunciado.id_rango);
                    
                }else{
                    this.LoaderSpinnerShow();
                    await enunciadosEscalaAbreviadaDesarrolloService.store(this.enunciado_rango);
                    this.LoaderSpinnerHide();
                    Toast.fire({
                        icon: 'success',
                        title: 'Datos guardados con exito'
                    });
                    $('#modalItemAiepi').modal('hide');
                    this.cargarRango(this.idRango);
                }
            }catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
            
        },
        
        
        
    },
    async created(){
        this.idRango=this.$route.params.id;
        this.cargarRango(this.idRango);
        this.cargarEnunciados(this.idRango);
        this.cargarDimensiones();
    },
    
}
</script>

